import React, { useState } from 'react'

const AppContext = React.createContext({
  isAuthenticated: false,
  setAuthenticated: () => { }
})

const AppContextProvider = props => {
  const [isAuth, setAuth] = useState(false)

  return (
    <AppContext.Provider
      value={
        {
          isAuthenticated: isAuth,
          setAuthenticated: ia => setAuth(ia)
        }
      }
    >
      { props.children}
    </AppContext.Provider >
  )
}

export { AppContext, AppContextProvider }