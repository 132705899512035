import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import axios from 'axios'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@material-ui/lab';


import { CSVLink } from "react-csv";
import { GrDocumentCsv } from 'react-icons/gr'

import { generateReportData } from './generateReportData';
import { AppContext } from '../components/AppContext'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  formGrid: {
    flexGrow: 1,
    justifyContent: 'space-around',
    padding: 12,
    marginBottom: 10
  }
});

const Dashboard = () => {
  const [formState, setFormState] = useState({ companyIdValid: false, dateValid: true })
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
  const [report, setReport] = useState(
    {
      inventoryValidationReport: [],
      estimateErrs: {
        noShipDate: [],
        acceptRejectStatus: []
      }
    })

    const [isOpen, setOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)

  const context = useContext(AppContext)
  const history = useHistory();

  const headers = [
    { label: "ID Name", key: "name" },
    { label: "SKU", key: "sku" },
    { label: "QTY", key: "qty" },
    { label: "Pending QTY", key: "pendingQty" },
    { label: "QTY - Pending", key: "col5" },
  ]

  const classes = useStyles()

  useEffect(() => {
    context.setAuthenticated(true)
  }, [])

  const handleDateChange = e => {
    const d = e.target.value
    const dateValid = !isNaN(Date.parse(d))
    setFormState(prev => ({ ...prev, dateValid }))
    setSelectedDate(d);
  };

  const handlePullReportsData = async () => {
    try {
      if (selectedDate) {
        setLoading(true)
        const result = await axios.get(`/customReportData?date=${selectedDate}`)
        const rd = generateReportData(result.data.estimates, result.data.inventoryValuations, result.data.invoices)
        setReport(rd)
        // Report loaded successfully
        setOpen(true)
        setLoading(false)
      }
    }
    catch (err) {
      context.setAuthenticated(false)
      history.push('/')
    }
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" component="h3" gutterBottom>
        Quickbooks Custom Report
          </Typography>


      <Grid container >
        <Grid xs={12} className={classes.formGrid} container spacing={3}>
          <TextField
            required
            id="date"
            label="Date"
            type="date"
            value={selectedDate}
            onChange={(e) => handleDateChange(e)}
            error={!formState.dateValid}
            helperText={!formState.dateValid && "Enter a valid date mm/dd/yyyy"}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={e => handlePullReportsData(e)}
            disabled={!formState.dateValid || isLoading}
          >
            Pull Report
            {
              isLoading &&
              <CircularProgress color="primary" size='1rem' />
            }
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>ID Name</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell align="right">QTY</TableCell>
                  <TableCell align="right">Pending QTY</TableCell>
                  <TableCell align="right">QTY - Pending</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {report.inventoryValidationReport.map(row => (
                  <TableRow key={row.id}>
                    <TableCell scope="row">{row.name}</TableCell>
                    <TableCell scope="row">{row.sku}</TableCell>
                    <TableCell align="right">{row.qty.toLocaleString()}</TableCell>
                    <TableCell align="right">{row.pendingQty.toLocaleString()}</TableCell>
                    <TableCell align="right">{row.col5.toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} style={{ margin: 10 }}>
          <Typography component="h4">
            Estimates with no ship Date
            <br />
            {
              //report.estimateErrs.noShipDate.join(", ")
              report.estimateErrs.noShipDate.map(nsd => <Chip label={nsd} color='primary' variant="outlined" />)
            }
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ margin: 10 }}>
          <Typography component="h4">
            Estimates that have Accepted/Rejected status
            <br />
            {
              //report.estimateErrs.acceptRejectStatus.join(", ")
              report.estimateErrs.acceptRejectStatus.map(ars => <Chip label={ars} color='primary' variant="outlined" />)
            }
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ margin: 10 }}>
          <CSVLink data={report.inventoryValidationReport} headers={headers} filename={`Report ${selectedDate}.csv`}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              endIcon={<GrDocumentCsv />}
            >
              Download CSV
              </Button>
          </CSVLink>
        </Grid>
      </ Grid>
      <Snackbar open={isOpen} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert onClose={() => setOpen(false)} severity="success">
          Report generated successfully
         </Alert>
      </Snackbar>
    </Container>
  )
}

export default Dashboard
