import {
  BrowserRouter as Router,
} from "react-router-dom";
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { green } from '@material-ui/core/colors';

import { AppContextProvider } from './components/AppContext'
import Routes from './components/Routes'
import Login from './views/Login'
import { QboLogo } from "./components/QboLogo";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-between'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

function App() {

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <div className={classes.root}>
          <AppBar position="static" className={classes.root}>
            <Toolbar className={classes.root}>
              <QboLogo edge="start" className={classes.menuButton} color="inherit" aria-label="menu" />
              <Login />
            </Toolbar>
          </AppBar>
        </div>
        <Router>
          <Routes />
        </Router>
      </AppContextProvider>
    </ThemeProvider>
  );
}

export default App;
